<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Orden de Pedido - Nuevo</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
        
              <b-row>

                  <b-col md="3">
                    <b-form-group label="N° de Pedido :">
                      <b-form-input class="text-center" readonly v-model="purchase_order.number"></b-form-input>
                    </b-form-group>
                  </b-col>
 
                  <b-col md="3">
                    <b-form-group label="Fecha Emision:">
                      <b-form-input class="text-center" type="date" disabled v-model="purchase_order.broadcast_date"></b-form-input>
                      <small v-if="errors.broadcast_date" class="form-text text-danger">Seleccione una fecha</small>
                    </b-form-group>
                  </b-col>

                  <b-col md="3">
                    <b-form-group label="Almacen que solicita?">
                      <b-form-select ref="id_warehouse" v-model="purchase_order.id_warehouse" :options="warehouses"></b-form-select>
                      <small v-if="errors.id_warehouse"  class="form-text text-danger">Seleccione un almacen</small>
                    </b-form-group>
                  </b-col>

                    <b-col md="3">
                    <b-form-group label=".">
                      <b-button class="form-control btn" variant="primary" @click="modalProducts" ><i class="fas fa-cart-plus"></i> Productos (F2)</b-button>
                    </b-form-group>
                  </b-col>

  
                  <b-col md="12">
                    <b-form-group label="Observación:">
                      <b-form-textarea rows="1"  v-model="purchase_order.observation" max-rows="3"></b-form-textarea>
                    </b-form-group>
                  </b-col>

                  <cPurchaseOrderDetail/>
                  <small v-if="errors.purchase_order_detail" class="col-md-12 form-text text-center text-danger">Agregue productos</small>

                  <b-col md="5" class="mt-3">   
                  </b-col>

              </b-row>
         

              <b-row  class="justify-content-md-center mt-3">
                <b-col md="3">
                  <b-button type="submit" class="form-control text-white" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- Modal Products -->
    <ModalProducts />

    <!-- Modal Clients -->
    <ModalProviders />
    <!-- Modal Clients -->
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="113" @success="modalProducts" />
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
    
  </div>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");
import EventBus from '@/assets/js/EventBus';
import { mapState,mapActions } from "vuex";


// components
import ModalProviders from '@/views/components/ModalProvider'
import ModalProducts from './components/ModalProduct'
import cPurchaseOrderDetail from './components/PurchaseOrderDetail'
import LoadingComponent from './../pages/Loading'

export default {
  name: "UsuarioAdd",
  components:{
      ModalProducts,
      cPurchaseOrderDetail,
      ModalProviders,
      vSelect,
      LoadingComponent,
      Keypress: () => import('vue-keypress'),
  },
  data() {
    return {
      module: 'PurchaseOrderUser',
      role:2,
      isLoading:false,
      purchase_order: {
        id_purchase_order_user:'',
        id_user:'',
        id_warehouse:'',
        number:'',
        broadcast_date:moment(new Date()).local().format("YYYY-MM-DD"),
        observation:'',
        state: 1,
      },
      warehouses:[],
      //errors
      errors: {
        id_warehouse: false,
        number:false,
        broadcast_date:false,
        purchase_order_detail:false,
      },
      validate: false,
    };
  },
  mounted() {
    this.mLoadResetPurchaseOrderDetail();
    this.GetCorrelative();
    this.ListWarehouses();
  },
  methods: {
    GetCorrelative,
    ListWarehouses,
    modalProducts,
    AddPurchaseOrder,
    Validate,
    ...mapActions('PurchaseOrder',['mLoadResetPurchaseOrderDetail']),
  },

  computed: {
    ...mapState(["url_base"]),
    ...mapState('PurchaseOrder',['purchase_order_detail']),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function GetCorrelative() {
  let me = this;
  let url = this.url_base + "get-correlative/PurchaseOrderUser";
  axios({
    method: "GET",
    url: url,
    headers: {token: this.token,module: this.module,role: this.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.purchase_order.number = response.data.result.number;
    } 
  })
}


function ListWarehouses() {

  let me = this;
  let url = this.url_base + "warehouse/list-active";

  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: this.role},
  })
    .then(function (response) {
      me.warehouses.push({value : '', text: '-- Seleccione un almacen --'});
      if (response.data.status == 200) {
        let data = response.data.result;
        for (let index = 0; index < data.length; index++) {
          me.warehouses.push( { value : data[index].id_warehouse , text: data[index].name } );
        }
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function modalProducts() {
  let data = {
    role:2
  }
  EventBus.$emit('ModalProductsShow',data);
}

function AddPurchaseOrder() {
  let me = this;
  let url = me.url_base + "purchase-order-user/add";
  me.isLoading = true;
  me.purchase_order.purchase_order_detail = me.purchase_order_detail;
  me.purchase_order.id_user = me.user.id_user;
  let data = me.purchase_order;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json", token: me.token,module: me.module, role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.purchase_order.id_purchase_order_user = '';
        me.purchase_order.id_warehouse = '';
        me.purchase_order.id_user = '';
        me.purchase_order.number = '';
        me.purchase_order.broadcast_date = moment(new Date()).local().format("YYYY-MM-DD");
        me.purchase_order.observation = '';
        me.purchase_order.state = '1';
        me.mLoadResetPurchaseOrderDetail();
        me.GetCorrelative();
        Swal.fire({ icon: 'success', text: response.data.message, timer: 2000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message,timer: 2000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error',timer: 2000,})
        me.isLoading = false;
    });
}

function Validate() {

  this.validate = false;

  this.errors.number = this.purchase_order.number.length != 8 ? true : false;
  this.errors.broadcast_date = this.purchase_order.broadcast_date.length == 0 ? true : false;
  this.errors.id_warehouse = this.purchase_order.id_warehouse.length == 0 ? true : false;
  this.errors.purchase_order_detail = this.purchase_order_detail.length == 0 ? true : false;

  if (this.errors.number == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.broadcast_date == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.id_warehouse == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.purchase_order_detail == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }


  if (this.validate) {
    return false;
  }

  let me = this;

  Swal.fire({
    title: 'Esta seguro de guardar la orden de pedido?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Estoy de Acuerdo!'
  }).then((result) => {
    if (result.isConfirmed) {
      this.AddPurchaseOrder();
    }
  })


}



</script>
